/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql, Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'

import './styles.scss'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
  } = props

  const searchParams = useSearchParams(props.location)
  const title = 'Search Results | FastStore'
  const EVENT_API_BASE_PATH = 'https://sp.vtex.com'

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        language="pt-br"
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={[
          {
            rel: 'preconnect',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
          {
            rel: 'dns-prefetch',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
        ]}
      />

      <SROnly as="h1" text={title} />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
        <Breadcrumb
          // name={title}
          breadcrumbList={[
            { item: `/colecao/${title}/`, name: 'Dia dos pais', position: 1 },
          ]}
          type="single"
        />
      </div>

      <div>
        <h2 className="section-title__text">DIA DOS PAIS</h2>

        <div className="">
          <div className="featured-sports">
            <div>
              <Link to="/pesquisa/?q=TRILHA+E+TREKKING&2400=2411&77560=77654&facets=2400%2C77560&sort=score_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028559"
                    alt=""
                  />
                  <h3>Trilha / Trekking e Camping</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/pesquisa/?q=ACADEMIA+FITNESS&2400=2411&77560=77654&facets=2400%2C77560&sort=score_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028558"
                    alt=""
                  />
                  <h3>Academia e Fitness</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/pesquisa/?q=ciclismo&2400=2411&77560=77654&facets=2400%2C77560&sort=score_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028555"
                    alt=""
                  />
                  <h3>Ciclismo</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/pesquisa/?q=FUTEBOL+VOLEI+BASQUETE&2400=2411&77560=77654&facets=2400%2C77560&sort=score_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028557"
                    alt=""
                  />
                  <h3>Coletivos</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/collection?q=652&sort=orders_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028552"
                    alt=""
                  />
                  <h3>Até R$ 49,99</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/collection?q=653&sort=orders_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028554"
                    alt=""
                  />
                  <h3>Até R$ 69,99</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/collection?q=654&sort=orders_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028553"
                    alt=""
                  />
                  <h3>Até R$ 99,99</h3>
                </div>
              </Link>
            </div>
            <div>
              <Link to="/collection?q=655&sort=orders_desc&page=0">
                <div>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/ids/15028556"
                    alt=""
                  />
                  <h3>Até R$149,99</h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ProductGalleryV2
        title="Search Results"
        searchEngine="vtex"
        collection="645"
        type="collection"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchDiaDosPaisPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
